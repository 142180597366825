import { Component } from '@angular/core';
import { Navigation } from '@app/navigation/enums/navigation.enum';
import { BaseDashboardCardComponent } from '@smooved/ui';

@Component({
    selector: 'app-lead-passing',
    template: `
        <app-dashboard-card
            [first]="true"
            class=""
            [title]="'REAL_ESTATE_AGENT.START.LEAD_PASSING.TITLE' | translate"
            [text]="'REAL_ESTATE_AGENT.START.LEAD_PASSING.TEXT' | translate"
            [hasBorderBottom]="true"
            imageName="smooved-with-van.png"
            [last]="true"
        >
            <ng-container up-to-and-including-phone-landscape>
                <div class="u-flex-column u-flex-align-items-stretch">
                    <ng-container [ngTemplateOutlet]="actions"></ng-container>
                </div>
            </ng-container>
            <ng-container tablet-portrait-up>
                <ng-container [ngTemplateOutlet]="actions"></ng-container>
            </ng-container>
        </app-dashboard-card>

        <ng-template #actions>
            <app-button data-testid="lp-button" theme="secondary" [routerLink]="registerMoveRoute"
                >{{ 'REAL_ESTATE_AGENT.START.LEAD_PASSING.NEW' | translate }}
            </app-button>
        </ng-template>
    `,
})
export class LeadPassingComponent extends BaseDashboardCardComponent {
    public registerMoveRoute = `/${Navigation.RealEstateAgent}/${Navigation.RealEstateAgentRegisterMove}`;
}
