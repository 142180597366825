import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DbUtils, RxjsComponent, SimpleChangesUtils } from '@smooved/core';
import { BoilerMaintenanceService } from '@app/services/boiler-maintenance/services/boiler-maintenance.service';
import { BoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { ButtonAppearance, ModalSandbox, Svg, UiContext } from '@smooved/ui';
import { TranslateService } from '@ngx-translate/core';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';

@Component({
    selector: 'app-boiler-maintenance-section',
    templateUrl: './boiler-maintenance-section.component.html',
    styleUrls: ['./boiler-maintenance-section.component.scss'],
})
export class BoilerMaintenanceSectionComponent extends RxjsComponent implements OnChanges {
    @Input() transactionId: string;

    public boilerMaintenanceContract: BoilerMaintenanceContract;

    protected readonly DbUtils = DbUtils;
    protected readonly uiContext = UiContext;
    protected readonly buttonAppearance = ButtonAppearance;
    public emptySvg = Svg.Empty;

    constructor(
        public moveSandbox: MoveSandbox,
        private boilerMaintenanceService: BoilerMaintenanceService,
        private translateService: TranslateService,
        private modalSandbox: ModalSandbox
    ) {
        super();
    }

    public ngOnChanges({ transactionId }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(transactionId)) {
            this.boilerMaintenanceService.getContract(this.transactionId).subscribe((boilerMaintenanceContract) => {
                this.boilerMaintenanceContract = boilerMaintenanceContract;
            });
        }
    }

    public confirmCheckOff(): void {
        const config = {
            header: this.translateService.instant('ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.CONFIRM_MODAL.TITLE') as string,
            question: this.translateService.instant('ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.CHECKED_OFF.CONFIRM_MODAL.TEXT') as string,
        };
        this.modalSandbox.openConfirmModal(config, this.confirmCheckOffResult.bind(this), config, this.confirmCheckOffResult.bind(this));
    }

    private confirmCheckOffResult(result: boolean): void {
        if (!result) return;
        this.setCheckedOff(true);
    }

    public setConfirmed(confirmed: boolean): void {
        this.boilerMaintenanceService
            .setConfirmed(DbUtils.getStringId(this.boilerMaintenanceContract), confirmed)
            .subscribe((updatedBoilerMaintenanceContract) => {
                this.boilerMaintenanceContract = updatedBoilerMaintenanceContract;
            });
    }

    public setCheckedOff(checkedOff: boolean): void {
        this.boilerMaintenanceService
            .setCheckedOff(DbUtils.getStringId(this.boilerMaintenanceContract), checkedOff)
            .subscribe((updatedBoilerMaintenanceContract) => {
                this.boilerMaintenanceContract = updatedBoilerMaintenanceContract;
            });
    }
}
