import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BoilerMaintenanceContract } from '@app/services/boiler-maintenance/types/boiler-mainternance-offer';
import { PropertyAge } from '@app/services-components/order-info/property-age/property-age.constants';

@Component({
    selector: 'app-boiler-maintenance-details',
    templateUrl: 'boiler-maintenance-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoilerMaintenanceDetailsComponent {
    @Input() public boilerMaintenanceContract: BoilerMaintenanceContract;
    @Input() public propertyAge: PropertyAge;
}
