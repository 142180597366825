import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SelectInput, Svg } from '@smooved/ui';
import { filter } from 'rxjs/operators';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';
import { HomeConstructionYear } from '../../../shared/state/shared.reducer';

@Component({
    selector: 'app-home-construction-year',
    template: `
        <form [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p" (ngSubmit)="onSubmit($event)">
            <app-select-input
                [label]="'HOME_CONSTRUCTION_YEAR.LABEL' | translate"
                [hasMargin]="false"
                [hasMarginDouble]="true"
                [options]="homeConstructionYearOptions"
                [formControlName]="'homeConstructionYear'"
                [attr.data-testid]="'home-construction-year-input'"
            ></app-select-input>

            <app-previous-submit (previous)="previous.emit()"></app-previous-submit>
        </form>
    `,
    styles: [
        `
            :host {
                display: block;
                width: 100%;
            }
        `,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeConstructionYearComponent implements OnInit, OnDestroy {
    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<void> = new EventEmitter<void>();

    public form: UntypedFormGroup = this.formBuilder.group({
        homeConstructionYear: [null, Validators.required],
    });

    public homeConstructionYearOptions: SelectInput<HomeConstructionYear>[] = [
        {
            id: HomeConstructionYear.Before2000BuiltOrRenovated,
            svg: Svg.Pre2000,
            label: this.translateService.instant('HOME_CONSTRUCTION_YEAR.BEFORE_2000'),
            value: HomeConstructionYear.Before2000BuiltOrRenovated,
            name: 'home-construction-year',
        },
        {
            id: HomeConstructionYear.After2000BuiltOrRenovated,
            svg: Svg.Post2000,
            label: this.translateService.instant('HOME_CONSTRUCTION_YEAR.AFTER_2000'),
            value: HomeConstructionYear.After2000BuiltOrRenovated,
            name: 'home-construction-year',
        },
        {
            id: HomeConstructionYear.NewConstruction,
            svg: Svg.New,
            label: this.translateService.instant('HOME_CONSTRUCTION_YEAR.NEW_CONSTRUCTION'),
            value: HomeConstructionYear.NewConstruction,
            name: 'home-construction-year',
        },
    ];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private translateService: TranslateService,
        private route: ActivatedRoute,
        private moveSandbox: MoveSandbox
    ) {}

    public ngOnInit(): void {
        this.moveSandbox.metaOnce$.pipe(filter((meta) => !!meta)).subscribe((meta) => {
            if (meta.homeConstructionYear) {
                this.homeConstructionYearFormControl().patchValue(meta.homeConstructionYear);
            }
        });
    }

    public goToPrevious(): void {
        this.router
            .navigate(['../home-description'], {
                relativeTo: this.route,
            })
            .then();
    }

    public onSubmit(event): void {
        if (this.form.valid) {
            this.next.emit();
        }
    }

    public ngOnDestroy(): void {
        this.moveSandbox.patchProperty('meta.homeConstructionYear', this.homeConstructionYearFormControl().value);
    }

    private homeConstructionYearFormControl(): AbstractControl {
        return this.form.get('homeConstructionYear');
    }
}
