import { isValidPhoneNumber, NumberType, parsePhoneNumber, parsePhoneNumberFromString, PhoneNumber } from 'libphonenumber-js/max';
import { CountryCode } from '@core/enums';

const phoneTypeMobile: NumberType = 'MOBILE';

export class PhoneNumberUtils {
    public static parsePhoneNumber(phoneNumber: string, countryCode = CountryCode.Belgium): PhoneNumber {
        if (!phoneNumber) return null;
        return parsePhoneNumberFromString(phoneNumber, countryCode);
    }

    public static isValidPhoneNumber(phoneNumber: string, countryCode = CountryCode.Belgium): boolean {
        if (!phoneNumber) return false;
        return isValidPhoneNumber(phoneNumber, countryCode);
    }

    public static isValidMobilePhoneNumber(phoneNumber: string): boolean {
        if (!phoneNumber) return false;

        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, CountryCode.Belgium);
        if (!parsedPhoneNumber.isValid()) return false;
        return parsedPhoneNumber?.getType() === phoneTypeMobile;
    }
}
