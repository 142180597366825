import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { meterInfoSupplierSuccess } from '@app/admin/components/energy-documents-moving-address/energy-documents-moving-address.constants';
import { MailMeterReadingsModal } from '@app/admin/modals/mail-meter-readings/mail-meter-readings.modal';
import { EnergySupplier } from '@app/energy/enums/energy-supplier.enum';
import { EnergyUtils } from '@app/energy/utils/energy.utils';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { DbUtils } from '@smooved/core';
import { ModalSandbox, NotificationSandbox } from '@smooved/ui';

@Component({
    selector: 'app-energy-documents-moving-address',
    template: `
        <div [ngSwitch]="!!move?.energyDocumentsMovingAddressByAdmin">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.energyDocumentsMovingAddressByAdmin }">
                <div data-testid="checked-true" *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.ENERGY_DOCUMENT' | translate }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div data-testid="checked-false" *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.NO_ENERGY_DOCUMENT' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half">
                <app-button
                    *ngIf="!!move?.energyOffer?.energyOfferSelection && !move?.energyDocumentsMovingAddressByAdmin"
                    class="u-margin-right-half"
                    [disabled]="!move?.moveStates?.meterComplete"
                    (onClick)="openMailModal()"
                    theme="secondary"
                    >{{ 'SEND' | translate }}
                </app-button>
                <app-button
                    *ngSwitchCase="false"
                    (onClick)="check()"
                    [disabled]="!move?.moveStates?.meterComplete"
                    theme="secondary-outline"
                    >{{ 'CHECK' | translate }}
                </app-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyDocumentsMovingAddressComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly modalSandbox: ModalSandbox,
        protected readonly cdr: ChangeDetectorRef,
        private readonly notificationSandbox: NotificationSandbox
    ) {
        super(cdr, moveSandbox);
    }

    public openMailModal(): void {
        if (EnergyUtils.isSupplier(this.move, EnergySupplier.Engie)) {
            this.moveSandbox.postMeterReadings(DbUtils.getStringId(this.move)).subscribe((response) => {
                this.notificationSandbox.success(meterInfoSupplierSuccess);
                this.afterPostMeterReadingsHandler(response);
            });
        } else {
            this.moveSandbox.getMailMeterReadings(DbUtils.getStringId(this.move)).subscribe((response) => {
                this.modalSandbox.openClosableModal({
                    component: MailMeterReadingsModal,
                    config: {
                        input: {
                            moveId: DbUtils.getStringId(this.move),
                            content: response,
                        },
                    },
                    afterClosed: this.afterPostMeterReadingsHandler,
                });
            });
        }
    }

    private afterPostMeterReadingsHandler = (updatedMove: Move) => {
        if (updatedMove) {
            this.updateMove(updatedMove);
        }
    };

    public check(): void {
        this.moveSandbox.patchProperty(
            'energyDocumentsMovingAddressByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'energyDocumentsMovingAddressByAdmin',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }
}
