import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ReviewsService } from '@app/reviews/services/reviews.service';
import { PendingSurvey } from '@app/surveys/interfaces/pending-survey.interface';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { dateTimeFormatDefault } from '@core/constants';
import { TranslateService } from '@ngx-translate/core';
import { ButtonAppearance } from '@ui/button';
import { ConfirmModalConfig, ModalSandbox } from '@ui/modal';
import { UiContext } from '@ui/ui.enums';

@Component({
    selector: 'smvd-app-unpublished-review',
    templateUrl: 'unpublished-review.component.html',
})
export class UnpublishedReviewComponent {
    @Input() public pendingSurvey: PendingSurvey;
    @Input() public transactionId: string;

    @Output() public afterDelete = new EventEmitter<Move>();

    public readonly dateTimeFormatDefault = dateTimeFormatDefault;
    public readonly uiContext = UiContext;
    public readonly buttonAppearance = ButtonAppearance;

    constructor(
        private readonly uiSandbox: AppUiSandbox,
        private readonly reviewsService: ReviewsService,
        private readonly moveSandbox: MoveSandbox,
        private readonly translateService: TranslateService,
        private readonly modalSandbox: ModalSandbox
    ) {}

    public confirmDeleteUnpublishedReview(): void {
        const deleteUnpublishedReview = (confirm: boolean) => {
            if (!confirm) return;
            this.uiSandbox.moveLoading(true);
            this.reviewsService.deleteReviewByMove(this.transactionId).subscribe(() => {
                this.moveSandbox.fetch(this.transactionId, (move) => {
                    this.afterDelete.emit(move);
                    this.uiSandbox.moveLoading(false);
                });
            });
        };

        const config: ConfirmModalConfig = {
            data: this.translateService.instant('SURVEYS.UNPUBLISHED_REVIEW.DELETE.CONFIRM'),
        };

        this.modalSandbox.openConfirmModal(config, deleteUnpublishedReview, config, deleteUnpublishedReview);
    }
}
