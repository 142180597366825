import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { SimpleChangesUtils } from '@smooved/core';

@Component({
    selector: 'app-energy-ordered-by-admin',
    template: `
        <div [ngSwitch]="!!move?.energyOrderedByAdmin">
            <div
                data-testid="checked-true"
                class="u-flex-row u-flex-align-items-center"
                [ngClass]="{ 'u-color-muted': !move?.energyOrderedByAdmin }"
            >
                <div *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.ORDERED_BY_ADMIN' | translate }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div data-testid="checked-false" *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.NOT_ORDERED_BY_ADMIN' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half" *ngIf="move?.energyOrdered">
                <app-energy-open-booking-url
                    class="u-display-inline-block u-margin-right-half"
                    [move]="move"
                    *ngIf="showEnergyOpenBookingUrl"
                ></app-energy-open-booking-url>
                <app-energy-order
                    class="u-display-inline-block u-margin-right-half"
                    [move]="move"
                    (updateMove)="updateMove($event)"
                    *ngIf="showEnergyOrder"
                ></app-energy-order>
                <app-button *ngSwitchCase="false" (onClick)="check()" theme="secondary-outline">{{ 'CHECK' | translate }} </app-button>
            </div>
        </div>
    `,
    styleUrls: ['./energy-ordered-by-admin.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyOrderedByAdminComponent extends BaseServiceActionComponent implements OnChanges {
    public showEnergyOpenBookingUrl: boolean;
    public showEnergyOrder: boolean;

    constructor(
        protected readonly moveSandbox: MoveSandbox,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public ngOnChanges({ move }: SimpleChanges): void {
        if (SimpleChangesUtils.hasChanged(move)) {
            this.setShowFlags(move.currentValue);
        }
    }

    public check(): void {
        this.moveSandbox.patchProperty(
            'energyOrderedByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'energyOrderedByAdmin',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    private setShowFlags(move: Move): void {
        if (!move?.energyOffer?.energyOfferSelection || move?.energyOrderedByAdmin) {
            this.showEnergyOpenBookingUrl = false;
            this.showEnergyOrder = false;
            return;
        }

        const { electricityBookingId, gasBookingId } = move.energyOffer.energyOfferSelection;
        if (electricityBookingId || gasBookingId) {
            this.showEnergyOpenBookingUrl = false;
            this.showEnergyOrder = true;
            return;
        }

        this.showEnergyOpenBookingUrl = true;
        this.showEnergyOrder = false;
    }
}
