import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Meta } from '@app/move/interfaces/meta';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { HomeDescriptionSize } from '@app/shared/state/shared.reducer';
import { HomeDescription, RxjsComponent } from '@smooved/core';
import { SelectInput } from '@smooved/ui';
import { isNumber } from 'lodash';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import {
    calculateHomeDescriptionSizeApartmentBucketFn,
    calculateHomeDescriptionSizeClosedBucketFn,
    calculateHomeDescriptionSizeDetachedBucketFn,
    formControlNames,
    homeDescriptionOptions,
    homeDescriptionSizeOptionsApartment,
    homeDescriptionSizeOptionsClosed,
    homeDescriptionSizeOptionsDetached,
    homeSizeStep,
} from './home-description.constants';

function homeDescriptionApartmentLevelValidator(group: UntypedFormGroup): any {
    if (group) {
        if (
            group.get('homeDescription').value === HomeDescription.Apartment &&
            !isNumber(group.get('homeDescriptionApartmentLevel').value)
        ) {
            return { required: true };
        }
    }
    return null;
}

@Component({
    selector: 'app-home-description',
    templateUrl: './home-description.component.html',
    styleUrls: ['./home-description.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeDescriptionComponent extends RxjsComponent implements OnInit, OnDestroy {
    @Input() public withHomeDescriptionSize = true;
    @Input() public withHomeDescriptionSizeBuckets = true;

    @Output() public previous: EventEmitter<Meta> = new EventEmitter<Meta>();
    @Output() public next: EventEmitter<Meta> = new EventEmitter<Meta>();

    public form: UntypedFormGroup = this.formBuilder.group({
        homeDescription: [null, Validators.required],
        homeDescriptionApartmentLevel: [0, Validators.min(0)],
    });

    public homeSizeStep = homeSizeStep;
    public homeDescriptionOptions: SelectInput<HomeDescription>[] = homeDescriptionOptions;
    public homeDescriptionSizeOptions: SelectInput<HomeDescriptionSize>[];
    public calculateHomeDescriptionSizeBucket: (size: number) => void = (_) => {};

    private get homeDescriptionFormControl(): AbstractControl {
        return this.form?.get(formControlNames.HomeDescription);
    }

    private get homeDescriptionSizeFormControl(): AbstractControl {
        return this.form?.get(formControlNames.HomeDescriptionSize);
    }

    private get homeDescriptionApartmentLevelFormControl(): AbstractControl {
        return this.form?.get(formControlNames.HomeDescriptionApartmentLevel);
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private moveSandbox: MoveSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.withHomeDescriptionSize) {
            this.showHomeDescriptionSizes();
        }

        this.form.setValidators([homeDescriptionApartmentLevelValidator]);

        this.moveSandbox.metaOnce$.pipe(filter((meta) => !!meta)).subscribe((meta) => {
            if (meta.homeDescription) {
                this.homeDescriptionFormControl.patchValue(meta.homeDescription);
            }

            if (this.withHomeDescriptionSize && this.form.get(formControlNames.HomeDescriptionSizeInput)) {
                this.form.get(formControlNames.HomeDescriptionSizeInput).patchValue(meta.homeDescriptionSizeInput);
            }

            if (this.withHomeDescriptionSize && meta.homeDescriptionSize) {
                this.homeDescriptionSizeFormControl.patchValue(meta.homeDescriptionSize);
            }

            if (isNumber(meta.homeDescriptionApartmentLevel)) {
                this.homeDescriptionApartmentLevelFormControl.patchValue(meta.homeDescriptionApartmentLevel);
            }
        });
    }

    public onSubmit(): void {
        if (this.form.valid) {
            this.next.emit(this.createPatch());
        }
    }

    public onPrevious(): void {
        this.previous.emit(this.createPatch());
    }

    public showDescriptionSize(): boolean {
        return this.withHomeDescriptionSize && !!this.homeDescriptionFormControl.value;
    }

    public showHomeDescriptionApartmentLevel(): boolean {
        return this.homeDescriptionFormControl.value === HomeDescription.Apartment;
    }

    private createPatch(): Meta {
        return {
            ...this.form.getRawValue(),
            homeDescriptionApartmentLevel:
                this.homeDescriptionFormControl.value === HomeDescription.Apartment
                    ? (this.homeDescriptionApartmentLevelFormControl.value as number)
                    : null,
        } as Meta;
    }

    private showHomeDescriptionSizes(): void {
        if (!this.withHomeDescriptionSizeBuckets) {
            const sizeInputCtrl = this.formBuilder.control(null, [Validators.required]);
            sizeInputCtrl.valueChanges
                .pipe(debounceTime(300), takeUntil(this.destroy$))
                .subscribe(this.handleCalculateHomeDescriptionSizeBucket);
            this.form.addControl(formControlNames.HomeDescriptionSizeInput, sizeInputCtrl);
        }

        // Even without buckets, the bucket still needs to be calculated
        this.form.addControl(formControlNames.HomeDescriptionSize, this.formBuilder.control(null, [Validators.required]));
        this.homeDescriptionFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.handleHomeDescriptionSizes);
    }

    private handleHomeDescriptionSizes = (homeDescription: HomeDescription): void => {
        this.unsetHomeDescriptionSizeInput();
        switch (homeDescription) {
            case HomeDescription.Apartment: {
                this.homeDescriptionSizeOptions = homeDescriptionSizeOptionsApartment;
                this.calculateHomeDescriptionSizeBucket = calculateHomeDescriptionSizeApartmentBucketFn;
                break;
            }
            case HomeDescription.Closed:
            case HomeDescription.HalfOpen: {
                this.homeDescriptionSizeOptions = homeDescriptionSizeOptionsClosed;
                this.calculateHomeDescriptionSizeBucket = calculateHomeDescriptionSizeClosedBucketFn;
                break;
            }
            case HomeDescription.Detached: {
                this.homeDescriptionSizeOptions = homeDescriptionSizeOptionsDetached;
                this.calculateHomeDescriptionSizeBucket = calculateHomeDescriptionSizeDetachedBucketFn;
                break;
            }
        }
    };

    private unsetHomeDescriptionSizeInput(): void {
        if (!this.form.get(formControlNames.HomeDescriptionSizeInput)) return;
        this.form.get(formControlNames.HomeDescriptionSizeInput).setValue(null);
    }

    private handleCalculateHomeDescriptionSizeBucket = (value: number): void => {
        const bucket = this.calculateHomeDescriptionSizeBucket(value);
        this.homeDescriptionSizeFormControl.patchValue(bucket);
    };
}
