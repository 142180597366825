<ng-container
    *ngIf="{ move: (move$ | async), isAdmin: (authSandbox.isAdmin$ | async), moveLoading: (uiSandbox.moveLoading$ | async) } as vm"
>
    <app-closable-modal-template maxWidth="none" *ngIf="!!vm.move" class="u-padding-none">
        <ng-container modal-header>
            <div class="u-flex-row u-flex-justify-content-center u-flex-align-items-center">
                <h5 class="__title">{{ vm.move | userName }}</h5>
                <ng-content select="[header-extra-options]"></ng-content>
            </div>
        </ng-container>
        <app-loading-container [loading]="vm.moveLoading">
            <div>
                <section class="u-margin-bottom-triple">
                    <header
                        class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
                    >
                        <div class="u-flex-row u-flex-align-items-center">
                            <h5>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.DATA' | translate }}</h5>
                            <app-copy-to-clipboard class="u-margin-left-half u-font-size-small u-color-muted"
                                                   *ngIf="vm.isAdmin"
                            >{{ vm.move | getId }}
                            </app-copy-to-clipboard
                            >
                        </div>
                        <a *ngIf="canEdit" (click)="openEdit()" class="u-link">{{ 'EDIT' | translate }}</a>
                    </header>
                    <ng-content select="detail"></ng-content>
                </section>

                <ng-content select="escalations"></ng-content>

                <section class="u-margin-bottom-triple">
                    <header
                        class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
                    >
                        <h5 class="u-flex-row u-flex-align-items-center">
                            {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.SERVICES' | translate }}
                            <span
                                *ngIf="vm.isAdmin && !!vm.move.additionalNotes"
                                class="material-icons u-color-error u-cursor-pointer u-padding-left-half"
                                (click)="showAdditionalNotes(vm.move.additionalNotes)"
                            >
                                info
                            </span>
                        </h5>
                        <div *ngIf="vm.isAdmin">
                            <ng-content select="interests"></ng-content>
                        </div>
                    </header>
                    <ng-content select="services"></ng-content>
                </section>

                <ng-content select="contactLogs"></ng-content>

                <section class="u-margin-bottom-triple"
                         *ngIf="!!showLogsSection && (authSandbox.isRealEstateAgent$ | async)">
                    <header
                        [ngSwitch]="uiSandbox.tabletPortraitUp$ | async"
                        class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
                    >
                        <h5 *ngSwitchCase="false">{{ 'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.MOBILE' | translate }}</h5>
                        <h5 *ngSwitchCase="true">{{ 'MOVE.CONTACT_LOGS_REAL_ESTATE_AGENT.TITLE.DESKTOP' | translate }}</h5>
                    </header>
                    <app-contact-logs-overview [move]="vm.move"></app-contact-logs-overview>
                </section>

                <section *ngIf="showNpsSection && (shareNps || vm.isAdmin)" class="u-margin-bottom-triple">
                    <header
                        class="u-flex-row u-flex-align-items-center u-flex-justify-content-space-between u-bordered-bottom u-padding-bottom-half u-margin-bottom"
                    >
                        <h5>{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.TITLE' | translate }}</h5>
                        <ui-dropdown-menu-button data-testid="admin-add-review" [label]="'ADD' | translate"
                                                 [disabled]="!canAddNps">
                            <button mat-menu-item uiDropdownMenuItem (click)="requestNps(vm.move)"
                                    [disabled]="!canRequestNps">
                                {{ 'BY_EMAIL' | translate }}
                            </button>
                            <button mat-menu-item uiDropdownMenuItem (click)="addNps()">{{ 'BY_PHONE' | translate }}
                            </button>
                        </ui-dropdown-menu-button>
                    </header>
                    <ng-content select="nps"></ng-content>
                </section>

                <ng-content></ng-content>
            </div>
        </app-loading-container>

        <mat-progress-spinner color="accent" *ngIf="vm.moveLoading" mode="indeterminate"></mat-progress-spinner>
    </app-closable-modal-template>
</ng-container>
