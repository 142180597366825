import { Component, forwardRef, Host, Input, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ProductsPrivacyModalComponent } from '@app/wizard/complete/components/products-privacy-modal/products-privacy-modal.component';
import { ProductsTermsAndConditionsModalComponent } from '@app/wizard/complete/components/products-terms-and-conditions-modal/products-terms-and-conditions-modal.component';
import { BaseInput } from '@smooved/ui';

@Component({
    selector: 'app-suppliers-terms-and-conditions',
    template: `
        <app-check-input
            [custom]="false"
            [(ngModel)]="innerModel"
            [hasMargin]="hasMargin"
            [hasMarginDouble]="hasMarginDouble"
            [width]="width"
            (ngModelChange)="onLegalChange($event)"
            [attr.data-testid]="'terms-and-conditions-consent-input'"
        >
            <span class="u-display-block"
                >{{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SUPPLIERS.1' | translate }}
                <a class="u-link u-color-muted" (click)="openTermsAndConditionsModal($event)">{{
                    'MOVE.LEGAL.TERMS_AND_CONDITIONS.SUPPLIERS.2' | translate
                }}</a>
                {{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SUPPLIERS.3' | translate }}
                <a class="u-link u-color-muted" (click)="openProductsPrivacyModal($event)">{{
                    'MOVE.LEGAL.TERMS_AND_CONDITIONS.SUPPLIERS.4' | translate
                }}</a>
                {{ 'MOVE.LEGAL.TERMS_AND_CONDITIONS.SUPPLIERS.5' | translate }}
            </span>
        </app-check-input>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SuppliersTermsAndConditionsComponent),
            multi: true,
        },
    ],
})
export class SuppliersTermsAndConditionsComponent extends BaseInput implements ControlValueAccessor {
    @Input() public formControlName: string;
    @Input() public width = 'auto';
    @Input() public hasMargin = true;
    @Input() public hasMarginDouble = false;

    public innerModel: boolean;
    public innerDisabled: boolean;
    public required: boolean;

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private dialog: MatDialog
    ) {
        super(controlContainer);
    }

    public openTermsAndConditionsModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ProductsTermsAndConditionsModalComponent);
    }

    public openProductsPrivacyModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ProductsPrivacyModalComponent);
    }

    public onLegalChange(value): void {
        this.propagateChange(this.innerModel);
    }

    public writeValue(value: boolean): void {
        this.innerModel = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }
}
