<form [formGroup]="form" class="u-padding-left-container">
    <div class="u-flex-responsive u-flex-align-items-center u-margin-bottom-double">
        <mat-button-toggle-group
            [hideSingleSelectionIndicator]="true"
            [hideMultipleSelectionIndicator]="true"
            [formControlName]="fields.FixedTariff"
            class="u-mw100p u-margin-right-double-tablet-portrait-up u-margin-bottom-up-to-and-including-phone-landscape is-secondary"
        >
            <mat-button-toggle [value]="true">{{ 'MOVE.ENERGY.SUGGESTIONS.FIXED_TARIFF' | translate }}
            </mat-button-toggle>
            <mat-button-toggle [value]="false">{{ 'MOVE.ENERGY.SUGGESTIONS.VARIABLE_TARIFF' | translate }}
            </mat-button-toggle>
        </mat-button-toggle-group>

        <app-check-input
            [custom]="false"
            width="auto"
            [hasMargin]="false"
            [hasMarginDouble]="false"
            [formControlName]="fields.PricesPerMonth"
            [option]="pricesPerMonthOption"
        ></app-check-input>
    </div>

    <div
        *ngIf="(energySandbox.energySuggestionsLoading$ | async) === false"
        class="u-scroll-container-horizontal u-flex-align-items-stretch u-margin-bottom"
    >
        <ng-container *ngIf="!!form.value[fields.FixedTariff]">
            <ng-container
                [ngTemplateOutlet]="suggestions"
                [ngTemplateOutletContext]="{ $implicit: { suggestions: energySandbox.fixedEnergySuggestions$ | async } }"
            >
            </ng-container>
            <div
                class="u-padding-right u-flex-column u-flex-justify-content-center __suggestion"
                *ngIf="energySandbox.variableEnergySuggestions$ | async as variableSuggestions"
            >
                <div
                    class="u-grid u-grid-col-2-repeat u-grid-justify-items-center u-grid-align-items-center u-grid-auto-rows u-margin">
                    <div *ngFor="let suggestion of variableSuggestions | slice: 0:maxSuppliersVisible">
                        <img
                            *ngIf="suggestion[suggestionIndex]?.image as image"
                            [src]="suppliersPath + image"
                            [alt]="'Logo' + suggestion[suggestionIndex]?.supplier"
                            class="u-display-block __suggestion-image"
                        />
                    </div>
                    <h5 *ngIf="variableSuggestions.length > maxSuppliersVisible" class="u-color-muted">
                        {{ variableSuggestions.length - maxSuppliersVisible }}+
                    </h5>
                </div>
                <div class="u-text-align-center">
                    <p class="u-padding">{{ 'MOVE.ENERGY.SUGGESTIONS.VARIABLE_OFFERS' | translate }}</p>
                    <button app-button context="secondary" class="u-margin" (click)="toggleTariff()">
                        {{ 'MOVE.ENERGY.SUGGESTIONS.VIEW_OFFERS' | translate }}
                    </button>
                    <a app-button appearance="link" class="u-color-text" (click)="showTariffDiff()">{{
                            'MOVE.ENERGY.SUGGESTIONS.TARIFF_DIFFERENCE.QUESTION' | translate
                        }}</a>
                </div>
            </div>
        </ng-container>
        <ng-container
            *ngIf="!form.value[fields.FixedTariff]"
            [ngTemplateOutlet]="suggestions"
            [ngTemplateOutletContext]="{ $implicit: { suggestions: energySandbox.variableEnergySuggestions$ | async } }"
        >
        </ng-container>
    </div>

    <app-previous-submit [showSubmit]="false" [ignoreTranslateXStyle]="true"
                         (previous)="previous.emit()"></app-previous-submit>

    <ng-template #suggestions let-item>
        <ng-container
            *ngIf="{
                vacancyPreferred: item.suggestions | productCondition: productCondition.VacancyPreferred,
                vacancyOther: item.suggestions | productCondition: productCondition.VacancyPreferred:productConditionAction.Exclude
            } as vm"
        >
            <ng-container
                *ngIf="isVacancy && !vacancyShowAll && item.suggestions?.length > 1 && vm.vacancyPreferred?.length > 0; else showAll"
            >
                <div *ngFor="let groupedSuggestion of vm.vacancyPreferred" class="u-padding-right">
                    <app-energy-suggestion
                        [formControlName]="fields.EnergySuggestion"
                        class="u-h100p"
                        [pricesPerMonth]="form.value[fields.PricesPerMonth]"
                        [groupedSuggestion]="groupedSuggestion"
                    >
                    </app-energy-suggestion>
                </div>
                <div class="u-flex-column u-flex-align-items-center u-flex-justify-content-center __list-not-preferred">
                    <div class="u-flex-row u-flex-align-items-center">
                        <ng-container *ngFor="let groupedSuggestion of vm.vacancyOther">
                            <img
                                *ngFor="let suggestion of groupedSuggestion"
                                class="u-margin-right-half"
                                width="120px"
                                [src]="'assets/images/suppliers/' + suggestion.image"
                            />
                        </ng-container>
                    </div>
                    <p class="u-margin-bottom u-text-align-center">{{ 'MOVE.ENERGY.SUGGESTIONS.VACANCY.OTHER.DESCRIPTION' | translate }}</p>
                    <button smvd-ui-button
                            (click)="doShowAll()">{{ 'MOVE.ENERGY.SUGGESTIONS.VACANCY.OTHER.CTA' | translate }}
                    </button>
                </div>
            </ng-container>
        </ng-container>

        <ng-template #showAll>
            <div *ngFor="let groupedSuggestion of item.suggestions" class="u-padding-right">
                <app-energy-suggestion
                    [formControlName]="fields.EnergySuggestion"
                    class="u-h100p"
                    [pricesPerMonth]="form.value[fields.PricesPerMonth]"
                    [groupedSuggestion]="groupedSuggestion"
                >
                </app-energy-suggestion>
            </div>
        </ng-template>
    </ng-template>
</form>
