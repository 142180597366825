<div class="u-flex-column u-margin-top">
    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-ultradark" id="test-label-offer-name">
                {{ boilerMaintenanceContract?.boilerMaintenanceOfferSnapshot?.offerName }}
            </p>
        </div>
    </div>

    <div class="u-flex-responsive u-margin-top">
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.MAINTENANCE_SCHEDULE.TITLE' | translate }}</p>
            <p
                class="u-color-gray-ultradark"
                id="label-maintenance-schedule"
                *ngIf="boilerMaintenanceContract?.maintenanceSchedule; else unknownLabel"
            >
                {{
                    'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.MAINTENANCE_SCHEDULE.' +
                        boilerMaintenanceContract?.maintenanceSchedule.toUpperCase() | translate
                }}
            </p>
        </div>
        <div class="u-flex-grow-1 u-mw50p">
            <p class="u-color-gray-dark">{{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.PROPERTY_AGE.TITLE' | translate }}</p>
            <p class="u-color-gray-ultradark" id="label-property-age" *ngIf="propertyAge; else unknownLabel">
                {{ 'ADMIN.DASHBOARD.DETAIL.BOILER_MAINTENANCE.PROPERTY_AGE.' + propertyAge.toUpperCase() | translate }}
            </p>
        </div>
    </div>
</div>

<ng-template #unknownLabel>
    <p class="u-color-gray-light">{{ 'ADMIN.DASHBOARD.DETAIL.UNKNOWN' | translate }}</p>
</ng-template>
