<smvd-ui-card [border]="false" [hasPadding]="false" class="u-background-gray-ultralight u-margin-bottom">
    <div class="u-flex-row u-flex-align-items-center u-padding">
        <app-icon icon="info"></app-icon>
        <p class="u-margin-left-half">
            {{ 'SURVEYS.UNPUBLISHED_REVIEW.LABEL' | translate: { time: pendingSurvey.publishDate | formatDate: dateTimeFormatDefault } }}
        </p>
    </div>
</smvd-ui-card>

<div class="u-flex-row u-margin-bottom-half">
    <div class="u-flex-column u-margin-right-double">
        <h6 class="u-margin-bottom-half u-color-muted">{{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.SCORE' | translate }}</h6>
        <h1 class="u-line-height-1">{{ pendingSurvey.review.score }}</h1>
    </div>
    <div class="u-flex-column">
        <div class="u-flex-column">
            <h6 class="u-margin-bottom-half u-color-muted">
                {{ 'REAL_ESTATE_AGENT.DASHBOARD.DETAIL.NPS.SUGGESTION' | translate }}
            </h6>
            <div [innerHTML]="pendingSurvey.review.content"></div>
        </div>
    </div>
</div>
<button smvd-ui-button [context]="uiContext.PrimaryDark" [appearance]="buttonAppearance.Stroked" (click)="confirmDeleteUnpublishedReview()">
    {{ 'SURVEYS.UNPUBLISHED_REVIEW.DELETE.CTA' | translate }}
</button>
