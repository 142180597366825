import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseStepContainer } from '@app/services-components/base-step/base-step.container';
import { options } from '@app/services-components/fulfillment/maintenance-schedule/maintenance-schedule.constants';
import { LineupModule } from '@app/services-components/lineup/lineup.module';
import { updateFulfillment } from '@app/services/state/services.actions';
import { selectFulfillmentInfo } from '@app/services/state/services.selectors';
import { ServicesState } from '@app/services/state/services.state';
import { PosthogServicesService } from '@app/tracking/posthog-services.service';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { UiSandbox } from '@smooved/ui';
import { SelectInputModule } from '@ui/form/select-input/select-input.module';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-maintenance-schedule-container',
    template: `
        <app-base-step-container (next)="onNext()">
            <h3 class="u-margin-bottom-md">{{ 'SERVICES.FULFILLMENT.MAINTENANCE_SCHEDULE.TITLE' | translate }}</h3>
            <div class="u-service-card u-margin-bottom-md">
                <app-select-input
                    [hasMargin]="false"
                    [hasMarginDouble]="false"
                    [options]="options"
                    [custom]="false"
                    [formControl]="control"
                ></app-select-input>
            </div>
        </app-base-step-container>
    `,
    standalone: true,
    imports: [CommonModule, SelectInputModule, TranslateModule, ReactiveFormsModule, LineupModule, BaseStepContainer],
})
export class MaintenanceScheduleContainer extends BaseStepContainer implements OnInit {
    public control = new FormControl(null, { validators: [Validators.required] });

    public readonly options = options;

    constructor(
        protected readonly router: Router,
        protected readonly route: ActivatedRoute,
        private readonly store: Store<ServicesState>,
        public readonly uiSandbox: UiSandbox,
        private readonly posthogServicesService: PosthogServicesService
    ) {
        super(route, router, uiSandbox);
    }

    public ngOnInit(): void {
        this.store
            .select(selectFulfillmentInfo)
            .pipe(take(1))
            .subscribe((fulfillment) => this.control.patchValue(fulfillment.maintenanceSchedule));
    }

    public onNext(): void {
        this.control.markAsTouched();
        if (this.control.invalid) return;
        const maintenanceSchedule = this.control.value;
        this.posthogServicesService.clickNextFulfillment(this.serviceType, { maintenanceSchedule });
        this.store.dispatch(
            updateFulfillment({
                maintenanceSchedule,
            })
        );
        if (!this.nextStep) return;
        void this.router.navigateByUrl(this.nextStep);
    }
}
