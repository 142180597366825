import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DateUtils, RxjsComponent } from '@smooved/core';
import { UiContext } from '@smooved/ui';
import { filter, takeUntil } from 'rxjs/operators';
import { AddressComponent } from '../../../form/components/address/address.component';
import { addressRequired } from '../../../form/validators/address.validator';
import { Move } from '../../../move/interfaces/move';
import { MoveSandbox } from '../../../move/sandboxes/move.sandbox';

@Component({
    selector: 'app-move-details',
    templateUrl: './move-details.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveDetailsComponent extends RxjsComponent implements OnInit, OnDestroy {
    @Input() public stepStart = 1;
    @Input() public stepEnd = 4;
    @Input() public showPrevious;

    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public next: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild(AddressComponent, { static: true }) public addressComponent: AddressComponent;

    public form: UntypedFormGroup = this.formBuilder.group({
        movingDate: [null, Validators.required],
        movingAddress: [null, addressRequired],
    });

    public readonly today = DateUtils.today();
    public readonly uiContext = UiContext;
    public movingDate: Date;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private moveSandbox: MoveSandbox
    ) {
        super();
    }

    public ngOnInit(): void {
        this.moveSandbox.movingAddressOnce$.pipe(filter((x) => !!x)).subscribe((movingAddress) => {
            this.movingAddressFormControl().patchValue(movingAddress);
        });
        this.moveSandbox.movingDateOnce$.pipe(filter((x) => !!x)).subscribe((movingDate) => {
            this.movingDateFormControl().patchValue(movingDate);
            this.updateMovingDate();
        });

        this.form.get('movingDate').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(this.updateMovingDate);
    }

    public onSubmit(event): void {
        this.addressComponent.markAsSubmitted();
        if (this.form.valid) {
            this.next.emit();
        }
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        const patch: Move = {
            movingDate: this.movingDateFormControl().value,
            user: {
                movingAddress: this.movingAddressFormControl().value,
            },
        };
        this.moveSandbox.patchProperty('', patch);
    }

    private movingDateFormControl(): AbstractControl {
        return this.form.get('movingDate');
    }

    private movingAddressFormControl(): AbstractControl {
        return this.form.get('movingAddress');
    }

    private updateMovingDate = (): void => {
        this.movingDate = this.form.get('movingDate').value;
    };
}
