<div class="u-flex-column u-flex-align-items-start">
    <h3>{{ 'REAL_ESTATE_AGENT.EOTS.CONTACT_DETAILS.TITLE' | translate }}</h3>
    <p class="u-color-muted u-margin-bottom-triple">{{
            'STEP_INDICATION' | translate: {
                start: stepStart,
                end: stepEnd
            }
        }}</p>

    <form (ngSubmit)="onSubmit()" [formGroup]="form" class="u-flex-column u-flex-align-items-start u-w100p">
        <h6 class="u-color-muted u-margin-bottom">{{ 'CONTACT_DETAILS.TITLE' | translate }}</h6>
        <div [ngSwitch]="(moveSandbox.hasContactDetails$ | async) && !editValues" class="u-w100p">
            <app-contact-details-readonly
                *ngSwitchCase="true"
                [ngClass]="{ 'u-margin-bottom': showPassportNumber, 'u-margin-bottom-double': !showPassportNumber }"
                [showNationalNumber]="showNationalNumber"
                [showPassportNumber]="showPassportNumber"
            ></app-contact-details-readonly>

            <div *ngSwitchCase="false" class="u-flex-column u-flex-align-items-start">
                <app-email-input [label]="'EMAIL' | translate" formControlName="email"
                                 [attr.data-testid]="'email-input'"
                                 width="467px"></app-email-input>

                <div class="__gender-and-first-name u-w100p">
                    <ui-dropdown-input
                        [formControlName]="formControlNames.Gender"
                        [id]="'gender'"
                        [label]="'GENDER' | translate"
                        [options]="genderOptions"
                        [width]="'150px'"
                        class="__gender"
                        [attr.data-testid]="'gender-input'"
                    ></ui-dropdown-input>

                    <app-text-input
                        [formControlName]="formControlNames.FirstName"
                        [id]="'first-name'"
                        [label]="'UI.FIRST_NAME.LABEL' | translate"
                        class="u-margin-right-tablet-portrait-up"
                        [attr.data-testid]="'first-name-input'"
                    ></app-text-input>
                </div>

                <app-text-input [formControlName]="'lastName'" [id]="'last-name'"
                                [label]="'UI.LAST_NAME.LABEL' | translate"
                                [attr.data-testid]="'last-name-input'"></app-text-input>

                <app-date-input
                    [formControlName]="formControlNames.DateOfBirth"
                    [id]="'date-of-birth'"
                    [label]="'DATE_OF_BIRTH' | translate"
                    [maxDate]="maxDateOfBirth"
                    [attr.data-testid]="'date-of-birth-input'"
                ></app-date-input>

                <ui-dropdown-input
                    *ngIf="authenticationSandbox.isRealEstateAgentOrAdmin$ | async"
                    [formControlName]="formControlNames.Language"
                    [label]="'LANGUAGE.LABEL' | translate"
                    [options]="languageOptions"
                    id="language"
                    [attr.data-testid]="'language-input'"
                ></ui-dropdown-input>

                <app-phone-input
                    [formControlName]="formControlNames.Phone"
                    [hasMarginDouble]="!showNationalNumber"
                    [hasMargin]="showNationalNumber"
                    [label]="'PHONE_NUMBER' | translate"
                    [attr.data-testid]="'phone-number-input'"
                ></app-phone-input>

                <app-national-number-input
                    *ngIf="showNationalNumber"
                    [formControlName]="formControlNames.NationalNumber"
                    [hasMarginDouble]="!showPassportNumber"
                    [hasMargin]="showPassportNumber"
                    [attr.data-testid]="'national-number-input'"
                ></app-national-number-input>
            </div>
            <a
                (click)="changeDetails()"
                *ngSwitchCase="true"
                class="u-display-block u-margin-top-half u-link u-font-size-small u-margin-bottom-double"
            >{{ 'MOVE.USER.CHANGE_DETAILS' | translate }}</a
            >
        </div>

        <div *ngIf="showPassportNumber" class="u-w100p">
            <app-passport-number-input
                [formControlName]="formControlNames.PassportNumber"
                [hasMarginDouble]="true"
                [hasMargin]="false"
            ></app-passport-number-input>
        </div>

        <ng-container *ngIf="moveSandbox.professional$ | async">
            <h6 class="u-color-muted u-margin-bottom">{{ 'COMPANY_DETAILS.TITLE' | translate }}</h6>

            <div class="u-flex-column u-flex-align-items-start u-margin-bottom">
                <app-text-input
                    [formControlName]="formControlNames.CompanyName"
                    [id]="'company-name'"
                    [label]="'COMPANY_DETAILS.COMPANY_NAME' | translate"
                ></app-text-input>

                <app-text-input
                    [formControlName]="formControlNames.VatNumber"
                    [id]="'vat-number'"
                    [label]="'COMPANY_DETAILS.COMPANY_NUMBER' | translate"
                ></app-text-input>
            </div>
        </ng-container>

        <div *ngIf="showLandAgent$ | async">
            <h6 class="u-color-muted u-margin-bottom">{{ 'LAND_AGENT' | translate }}</h6>
            <app-check-input
                [custom]="false"
                [formControlName]="formControlNames.LandAgent"
                [hasMarginDouble]="true"
                [hasMargin]="false"
                [option]="landAgentOption"
            ></app-check-input>
        </div>

        <h6 class="u-color-muted u-margin-bottom">{{ 'CONTACT_ADDRESS' | translate }}</h6>

        <div [ngSwitch]="!!otherContactAddressFormControl().value" class="u-flex-column u-margin-bottom u-w100p">
            <app-address
                *ngSwitchCase="true"
                [formControlName]="formControlNames.Address"
                [labelResource]="'CONTACT_ADDRESS'"
            ></app-address>

            <div *ngSwitchCase="false">
                <app-address-output [address]="prefilledContactAddress$ | async"
                                    class="u-font-size-medium"></app-address-output>
            </div>
        </div>

        <app-check-input
            [custom]="false"
            [formControlName]="formControlNames.OtherAddress"
            [hasMarginDouble]="true"
            [hasMargin]="false"
            [option]="otherContactAddressOption"
            width="auto"
        ></app-check-input>

        <app-previous-submit (previous)="previous.emit()"></app-previous-submit>
    </form>
</div>
