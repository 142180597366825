import { Component, forwardRef, Host, Input, OnDestroy, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { ConsentModalComponent } from '@app/start/components/consent-modal/consent-modal.component';
import { TranslationSandbox } from '@app/translation/sandboxes/translation.sandbox';
import { ProductsPrivacyModalComponent } from '@app/wizard/complete/components/products-privacy-modal/products-privacy-modal.component';
import { ProductsTermsAndConditionsModalComponent } from '@app/wizard/complete/components/products-terms-and-conditions-modal/products-terms-and-conditions-modal.component';
import { environment } from '@environments/environment';
import { BaseInput } from '@smooved/ui';
import { combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-gdpr',
    template: `
        <ng-container *ngIf="{ supplierSelected: supplierSelected$ | async } as vm">
            <app-check-input
                [custom]="false"
                width="auto"
                [(ngModel)]="innerModel"
                (ngModelChange)="onLegalChange($event)"
                [attr.data-testid]="'terms-and-conditions-consent-input'"
                class="__terms-and-conditions"
            >
                <span class="u-display-block"
                    >{{ 'MOVE.LEGAL.GDPR.1' | translate }}
                    <a class="u-link u-color-muted" target="_blank" [href]="termsAndConditionsUri" download>{{
                        'MOVE.LEGAL.GDPR.2' | translate
                    }}</a>
                    {{ 'MOVE.LEGAL.GDPR.3' | translate }}
                    <a class="u-link u-color-muted" (click)="openSmoovedPrivacyModal($event)">{{ 'MOVE.LEGAL.GDPR.4' | translate }}</a>

                    <ng-container *ngIf="vm.supplierSelected; else noSupplierSelected">
                        {{ 'MOVE.LEGAL.GDPR.5.WITH_SUPPLIER' | translate }}
                        <a class="u-link u-color-muted" (click)="openTermsAndConditionsModal($event)">{{
                            'MOVE.LEGAL.GDPR.6' | translate
                        }}</a>
                        {{ 'MOVE.LEGAL.GDPR.7' | translate }}
                        <a class="u-link u-color-muted" (click)="openProductsPrivacyModal($event)">{{ 'MOVE.LEGAL.GDPR.8' | translate }}</a>
                        {{ 'MOVE.LEGAL.GDPR.9' | translate }}
                    </ng-container>
                </span>
            </app-check-input>

            <ng-template #noSupplierSelected>
                {{ 'MOVE.LEGAL.GDPR.5.WITHOUT_SUPPLIER' | translate }}
            </ng-template>
        </ng-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GdprComponent),
            multi: true,
        },
    ],
})
export class GdprComponent extends BaseInput implements ControlValueAccessor, OnDestroy {
    @Input() public formControlName: string;
    @Input() public width = 'auto';

    public innerModel: boolean;
    public innerDisabled: boolean;
    public required: boolean;
    public termsAndConditionsUri: string;

    public supplierSelected$ = combineLatest(
        this.moveSandbox.energySelected$,
        this.moveSandbox.telecomSelected$,
        this.moveSandbox.energyOfferSelection$,
        this.moveSandbox.telecomOfferSelection$
    ).pipe(
        map(([energySelected, telecomSelected, energySuggestion, telecomSuggestion]) => {
            return (energySelected && energySuggestion) || (telecomSelected && telecomSuggestion);
        })
    );

    constructor(
        @Optional() @Host() @SkipSelf() controlContainer: ControlContainer,
        private dialog: MatDialog,
        private moveSandbox: MoveSandbox,
        private translationSandbox: TranslationSandbox
    ) {
        super(controlContainer);
    }

    public ngOnInit(): void {
        // Unset legal on init
        this.moveSandbox.patchMoveState('legal', 'smoovedTermsAndConditions', false);
        this.moveSandbox.patchMoveState('legal', 'gdpr', false);
        super.ngOnInit();
        this.translationSandbox.selected$.pipe(takeUntil(this.destroy$)).subscribe((lang) => {
            this.termsAndConditionsUri = environment.termsAndConditionsUri[lang];
        });
    }

    public openSmoovedPrivacyModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ConsentModalComponent);
    }

    public openTermsAndConditionsModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ProductsTermsAndConditionsModalComponent);
    }

    public openProductsPrivacyModal(event: MouseEvent): void {
        event.preventDefault();
        this.dialog.open(ProductsPrivacyModalComponent);
    }

    public onLegalChange(value): void {
        this.moveSandbox.patchMoveState('legal', 'smoovedTermsAndConditions', value);
        this.moveSandbox.patchMoveState('legal', 'gdpr', value);
        this.propagateChange(this.innerModel);
    }

    public writeValue(value: boolean): void {
        this.innerModel = value;
    }

    public setDisabledState(isDisabled: boolean): void {
        this.innerDisabled = isDisabled;
    }
}
