import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MailAdvanceAmountModal } from '@app/admin/modals/mail-advance-amount/mail-advance-amount.modal';
import { BaseServiceActionComponent } from '@app/move/components/base-service-action/base-service-action.component';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-energy-advance-amount',
    template: `
        <div [ngSwitch]="!!move?.energyAdvanceAmountByAdmin">
            <div class="u-flex-row u-flex-align-items-center" [ngClass]="{ 'u-color-muted': !move?.energyAdvanceAmountByAdmin }">
                <div data-testid="checked-true" *ngSwitchCase="true">
                    <app-check-with-background width="20" height="20" class="u-margin-right-half"></app-check-with-background>
                    <span class="u-margin-right-half">{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.ADVANCE_AMOUNT' | translate }}</span>
                    <a (click)="uncheck()" class="u-link">{{ 'UNCHECK' | translate }}</a>
                </div>
                <div data-testid="checked-false" *ngSwitchCase="false">
                    <div class="u-flex-row u-flex-align-items-center">
                        <app-svg [name]="emptySvg" width="24" height="24" class="u-margin-right-half"></app-svg>
                        <span>{{ 'ADMIN.DASHBOARD.DETAIL.ENERGY.NO_ADVANCE_AMOUNT' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="u-margin-top-half" *ngIf="move?.energyOrderedByAdmin">
                <app-button
                    *ngIf="!!move?.energyOffer?.energyOfferSelection && !move?.energyAdvanceAmountByAdmin"
                    class="u-margin-right-half"
                    (onClick)="openMailModal()"
                    theme="secondary"
                    >{{ 'SEND' | translate }}
                </app-button>
                <app-button *ngSwitchCase="false" (onClick)="check()" theme="secondary-outline">{{ 'CHECK' | translate }} </app-button>
            </div>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnergyAdvanceAmountComponent extends BaseServiceActionComponent {
    constructor(
        protected readonly moveSandbox: MoveSandbox,
        private readonly dialog: MatDialog,
        protected readonly cdr: ChangeDetectorRef
    ) {
        super(cdr, moveSandbox);
    }

    public openMailModal(): void {
        this.moveSandbox.getMailAdvanceAmount(this.move?._id).subscribe((response) => {
            const modal = this.dialog.open(MailAdvanceAmountModal, {
                data: {
                    content: response,
                    moveId: this.move?._id,
                },
            });
            modal
                .afterClosed()
                .pipe(take(1))
                .subscribe((updatedMove) => {
                    if (updatedMove) {
                        this.updateMove(updatedMove);
                    }
                });
        });
    }

    public check(): void {
        this.moveSandbox.patchProperty(
            'energyAdvanceAmountByAdmin',
            true,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }

    public uncheck(): void {
        this.moveSandbox.patchProperty(
            'energyAdvanceAmountByAdmin',
            false,
            true,
            (move) => {
                this.updateMove(move);
            },
            false,
            this.move,
            false
        );
    }
}
